import { Link, RichText } from 'ui/components';
import styles from './EventMeta.module.scss';
import { LinkButton } from 'ui/components';

export interface EventMetaProps {
	date: string;
	info: string;
	link: Models.Link;
}

export const EventMeta: React.FC<EventMetaProps> = ({ date, info, link }) => {
	return (
		<div className={styles.EventMeta}>
			{date && <p className={styles.EventMeta_date}>{date}</p>}
			{info && <RichText className={styles.EventMeta_info} content={info} />}
			{link?.url && (
				<LinkButton
					className={styles.EventMeta_link}
					style="secondary"
					size="sm"
					url={link.url}
					title={link.name}
					aria-label={link.name}
					target={link.target}
				>
					{link.name}
				</LinkButton>
			)}
		</div>
	);
};
