import cx from 'classnames';
import { useState } from 'react';
import { Icon, Link } from 'ui/components';
import styles from './ServiceNavigation.module.scss';
import { ReactComponent as SearchIcon } from 'ui/icons/icon-search.svg';

export interface ServiceNavigationProps extends UI.ServiceNavigation {
	className?: string;
	isMobile?: boolean;
	toggleSearch?: () => void;
	handleLinkClick?: (e?: React.MouseEvent) => void;
	colorTheme?: string;
	isSearchPageIncluded?: boolean;
}

export const ServiceNavigation: React.FC<ServiceNavigationProps> = ({
	className,
	items,
	ariaLabel,
	searchInputPlaceholder,
	toggleSearch,
	handleSearch,
	handleLinkClick,
	isMobile,
	searchButtonText,
	colorTheme = 'dark',
	isSearchPageIncluded,
}) => {
	const [searchValue, setSearchValue] = useState<string>('');

	return (
		<nav
			className={cx(styles.ServiceNavigation, { [styles.ServiceNavigation___desktop]: !isMobile }, className)}
			aria-label={ariaLabel}
			role="navigation"
		>
			{isSearchPageIncluded && (
				<div className={styles.ServiceNavigation_search}>
					{isMobile && (
						<input
							className={styles.ServiceNavigation_searchInput}
							id="search"
							type="search"
							placeholder={searchInputPlaceholder}
							onKeyUp={(e) => {
								setSearchValue((e.target as HTMLInputElement)?.value);
								e.key === 'Enter' && handleSearch?.(searchValue);
							}}
						/>
					)}
					<button
						className={cx(
							styles.ServiceNavigation_searchButton,
							styles[`ServiceNavigation_searchButton___${colorTheme}`],
						)}
						onClick={() => (isMobile ? handleSearch(searchValue) : toggleSearch())}
						title={searchButtonText}
						arial-label={searchButtonText}
						aria-controls={isMobile ? null : 'SearchBox'}
					>
						<Icon className={styles.ServiceNavigation_searchIcon}>
							<SearchIcon />
						</Icon>
					</button>
				</div>
			)}
			{items && items.length > 0 && (
				<ul className={styles.ServiceNavigation_list}>
					{items.map((item, index) => (
						<li className={styles.ServiceNavigation_item} key={index} aria-label={item.name}>
							<Link
								className={cx(styles.ServiceNavigation_link, {
									[styles.ServiceNavigation_link___isActive]: item.isActive,
								})}
								{...item}
								clickHandler={() => handleLinkClick?.()}
							>
								{item.name}
							</Link>
						</li>
					))}
				</ul>
			)}
		</nav>
	);
};
