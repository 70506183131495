import cx from 'classnames';
import styles from './Accordion.module.scss';
import React from 'react';
import { Heading } from 'ui/components';
import { AccordionItem, AccordionItemProps } from './AccordionItem';

export interface AccordionProps {
	className?: string;
	title?: string;
	accordionItems: AccordionItemProps[];
}

export const Accordion: React.FC<AccordionProps> = ({ className, title, accordionItems }) => {
	return (
		<div className={cx(styles.Accordion, className)}>
			{title && (
				<Heading className={styles.Accordion_heading} headingLevel={'h2'} style="sm">
					{title}
				</Heading>
			)}

			<div className={styles.Accordion_items}>
				{accordionItems.map((accordionItem, index) => (
					<AccordionItem {...accordionItem} key={index} />
				))}
			</div>
		</div>
	);
};
