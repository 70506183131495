import cx from 'classnames';
import throttle from 'lodash.throttle';
import { useRef, useEffect, useMemo, useState } from 'react';
import styles from './ArticleTable.module.scss';
import { RichText } from 'ui/components';

export interface ArticleTableProps {
	content: string;
	className?: string;
}

export const ArticleTable: React.FC<ArticleTableProps> = ({ className, content }) => {
	const [isScrolled, setIsScrolled] = useState(false);
	const richTextRef = useRef(null);

	const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
		const element = e.target as HTMLDivElement;
		setIsScrolled(element.scrollWidth - element.clientWidth <= Math.ceil(element.scrollLeft + 1));
	};

	const throttledScrollHandler = useMemo(() => throttle(scrollHandler, 300), []);

	// Clean up the throttled function on unmount
	useEffect(() => {
		// Remove fading mask if rich text has overflowing content
		if (richTextRef.current.offsetWidth === richTextRef.current.scrollWidth)
			richTextRef.current?.classList.add(styles.isScrolled);

		return () => throttledScrollHandler.cancel();
	}, [throttledScrollHandler]);

	return (
		<RichText
			className={cx(styles.ArticleTable, { [styles.isScrolled]: isScrolled }, className)}
			content={content}
			onScroll={scrollHandler}
			forwardedRef={richTextRef}
		/>
	);
};
