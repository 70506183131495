import cx from 'classnames';
import { Grid, GridCell, LinkButton, SearchResultWrapper } from 'ui/components';
import styles from './SearchResultList.module.scss';
import { SearchResultItem, SearchResultItemProps } from 'ui/components/2-molecules/SearchResultItem';

export interface SearchResultListProps {
	className?: string;
	id?: string;
	heading: string | React.ReactNode;
	link?: Content.Link;
	results?: SearchResultItemProps[];
	isLoading: boolean;
	filter?: React.ReactNode;
	children?: React.ReactNode;
	resultListRef?: React.RefObject<HTMLDivElement>;
}

export const SearchResultList: React.FC<SearchResultListProps> = ({
	className,
	id,
	results,
	link,
	heading,
	isLoading,
	filter,
	children,
	resultListRef,
}) => {
	const linkButton: JSX.Element = link ? (
		<LinkButton title={link.name} style="secondary" target={link.target} url={link.url}>
			{link.name}
		</LinkButton>
	) : null;

	return (
		<div className={cx(styles.SearchResultList, className)} id={id} ref={resultListRef}>
			<SearchResultWrapper
				heading={heading as string}
				isLoading={isLoading}
				className={styles.SearchResultList_wrapper}
				linkButton={linkButton}
				filter={filter}
			>
				{results && results.length > 0 && (
					<Grid className={styles.SearchResultList_grid} wrap asList nogutter>
						{results.map((item, index) => (
							<GridCell
								className={styles.SearchResultList_cell}
								asListItem
								key={index}
								desktopWidth={'100'}
								tabletWidth={'100'}
								mobileWidth={'100'}
								center={false}
							>
								<SearchResultItem {...item} />
							</GridCell>
						))}
					</Grid>
				)}
				{children}
			</SearchResultWrapper>
		</div>
	);
};
