import { replacePartOfString } from 'helpers/string/string';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Spinner, BlurbCard, SearchField, Paragraph } from 'ui/components';

export interface SearchResultsProps {
	numberOfResults: number;
	query: string;
	results: {
		heading?: string;
		link: {
			url: string;
		};
		kicker?: string;
		text?: string;
		date?: Date;
	}[];
}

export interface SearchProps {
	searchButtonText: string;
	searchInputPlaceholder: string;
	requiredFieldErrorMessage: string;
	searchResultText: string;
	searchErrorText: string;
	searchResultFetcher: (searchQuery: string) => {
		isLoading: boolean;
		error: string;
		searchResult: SearchResultsProps;
	};
}

export const Search: React.FC<SearchProps> = ({
	searchButtonText,
	searchInputPlaceholder,
	requiredFieldErrorMessage,
	searchResultText,
	searchErrorText,
	searchResultFetcher,
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<{ query: string }>();

	const [searchQuery, setSearchQuery] = useState('');

	const onSubmit = async ({ query }) => setSearchQuery(query);

	const { error, isLoading, searchResult } = searchResultFetcher(searchQuery) ?? {};
	const { numberOfResults, query, results } = searchResult ?? {};

	return (
		<div className="u-grid__cell u-grid__cell--width-100 u-grid__cell--width-100@md">
			<Form onSubmit={handleSubmit(onSubmit)} className="u-bottom-margin--lg" getErrorTxtFromSummary={null}>
				<SearchField
					buttonText={searchButtonText}
					id="search"
					name="query"
					placeholder={searchInputPlaceholder}
					register={register}
					state={errors.query ? { hasError: true, required: true } : { required: true }}
					errorMessage={requiredFieldErrorMessage}
					className={errors.query && 'hasError'}
				/>
			</Form>
			{isLoading && <Spinner />}
			{error && <p>{searchErrorText}</p>}
			{results && results.length !== 0 && (
				<div>
					{numberOfResults && (
						<Paragraph className="u-bottom-margin--sm">
							{replacePartOfString(searchResultText, '${num}', numberOfResults?.toString())}:{' '}
							<b>{query}</b>
						</Paragraph>
					)}
					<div className="u-grid u-grid--wrap u-grid--container u-grid--row-gap">
						{results?.map((result, index) => (
							<BlurbCard key={index} {...result} />
						))}
					</div>
				</div>
			)}
		</div>
	);
};
