import logger from 'helpers/logger';
import { DictionaryInterface, Replacement } from './dictionaryInterface';

export const DictionaryFactory = (items: Content.DictionaryItem[], culture: string): DictionaryInterface => {
	return {
		getValue(key: string, replacements?: Replacement[], defaultValue?: string): string {
			const dictionaryValue: Content.DictionaryItem = items.find(
				(element) => element?.key.toLocaleLowerCase() === key.toLocaleLowerCase(),
			);
			let dictionaryString = dictionaryValue?.values?.find(
				(element) => element?.culture.toLocaleLowerCase() === culture.toLocaleLowerCase(),
			)?.value;
			if (!dictionaryString) {
				//Cannot find dictionary text
				const errorMessage = `[DICTIONARY ${key} MISSING FOR ${culture}] ${
					defaultValue ? '- ' + defaultValue : ''
				} `;

				if (defaultValue) {
					logger.warn(errorMessage);
					return defaultValue;
				} else {
					return errorMessage;
				}
			}

			if (!replacements) {
				return dictionaryString;
			}

			replacements.forEach((replacement) => {
				return (dictionaryString = dictionaryString.replace('${' + replacement.name + '}', replacement.value));
			});

			return dictionaryString;
		},
		items: items,
		culture: culture as string,
	};
};