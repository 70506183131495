import cx from 'classnames';
import styles from './LinkButton.module.scss';
import { Link, LinkProps } from 'ui/components';

export interface LinkButtonProps extends LinkProps {
	children: React.ReactNode;
	style?: UI.ButtonStyle;
	size?: UI.ButtonSize;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
	children,
	className,
	url,
	style = 'primary',
	size = 'md',
	...props
}) => (
	<Link
		url={url}
		className={cx(styles.LinkButton, styles[`LinkButton___${style}`], styles[`LinkButton___${size}`], className)}
		{...props}
	>
		{children}
	</Link>
);
