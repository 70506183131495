import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'ui/components';
import { SubNavigation } from '../../SubNavigation';
import { ReactComponent as IconChevron } from 'ui/icons/icon-chevron-down.svg';

import styles from './DesktopNavigationItem.module.scss';
import { useEscapeKey } from 'ui/hooks/useEscapeKey';

export interface DesktopNavigationItemProps extends UI.NavigationItem {
	currentPageId: number;
	showNavigation: (id: number) => void;
	hideNavigation: (delay?: number) => void;
	currentNavId: number | null;
	previousButtonText: string;
	previousButtonAriaLabel: string;
	colorTheme?: string;
}

const HIDE_NAV_DELAY = 2000;

export const DesktopNavigationItem: React.FC<DesktopNavigationItemProps> = ({
	id,
	title,
	url,
	children,
	currentPageId,
	hideNavigation,
	showNavigation,
	currentNavId,
	previousButtonText,
	previousButtonAriaLabel,
	colorTheme = 'dark',
}) => {
	const liRef = useRef<HTMLLIElement>(null);
	const navRef = useRef<HTMLDivElement>(null);
	const [isSticky, setIsSticky] = useState(false);

	const handleEsc = () => {
		hideNavigation();
		setIsSticky(false);
	};

	useEffect(() => {
		if (currentNavId !== id) setIsSticky(false);
	}, [id, currentNavId]);

	useEscapeKey(handleEsc);

	return (
		<li
			onMouseLeave={() => {
				if (!isSticky) hideNavigation(HIDE_NAV_DELAY);
			}}
			onMouseEnter={() => showNavigation(id)}
			onClick={(e) => {
				e.stopPropagation();
				if (id === currentNavId && isSticky) {
					hideNavigation();
					setIsSticky(false);
				} else {
					showNavigation(id);
					setIsSticky(true);
				}
			}}
			onFocus={() => showNavigation(id)}
			onBlur={() => {
				if (!isSticky) hideNavigation();
			}}
			ref={liRef}
			className={cx(
				styles.DesktopNavigationItem,
				{ [styles.isActive]: currentNavId === id },
				{ [styles.hasChildren]: children?.length },
			)}
		>
			<Link
				clickHandler={() => hideNavigation()}
				title={title}
				className={styles.DesktopNavigationItem_link}
				url={children?.length ? null : url}
			>
				{title}
				{children?.length > 0 && <IconChevron className={styles.DesktopNavigationItem_icon} />}
			</Link>
			<CSSTransition
				classNames={{ ...styles }}
				timeout={500}
				in={currentNavId === id || isSticky}
				nodeRef={navRef}
			>
				<nav
					className={styles.DesktopNavigationItem_subNavContainer}
					aria-label={title}
					onClick={(e) => {
						e.stopPropagation();
					}}
					ref={navRef}
				>
					<div className={styles.DesktopNavigationItem_subNavContainer_inner}>
						<SubNavigation
							className={styles.DesktopNavigationItem_subNav}
							currentPageId={currentPageId}
							navId={id}
							items={children}
							itemHeader={
								children?.length > 0 ? { title: title, url: url, id: id, children: null } : null
							}
							hideNavigation={() => {
								if (id !== currentNavId) setIsSticky(false);
								hideNavigation();
							}}
							previousButtonText={previousButtonText}
							previousButtonAriaLabel={previousButtonAriaLabel}
							colorTheme={colorTheme}
						/>
					</div>
				</nav>
			</CSSTransition>
		</li>
	);
};
