import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { RichText } from 'ui/components';
import styles from './AccordionItem.module.scss';

export interface AccordionItemProps {
	id: string;
	title: string;
	htmlContent: string;
	openByDefault?: boolean;
	focusByDefault?: boolean;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
	id,
	title,
	htmlContent,
	openByDefault,
	focusByDefault,
}) => {
	const [isFocus, setIsFocus] = useState(focusByDefault);
	const [height, setHeight] = useState<'auto' | number>(openByDefault ? 'auto' : 0);
	const isOpen = height !== 0;
	const firstRender = useRef(true);

	useEffect(() => {
		// If it's the first render, openByDefault takes precedence over focusByDefault
		if (firstRender.current && openByDefault) {
			firstRender.current = false;
			return;
		}

		setIsFocus(focusByDefault);
		setHeight(focusByDefault ? 'auto' : 0);
	}, [focusByDefault, openByDefault]);

	return (
		<>
			<h3
				id={id}
				className={cx(
					styles.AccordionItem_heading,
					{ [styles.AccordionItem_heading___isFocus]: isFocus },
					{
						[styles.AccordionItem_heading___isOpen]: isOpen,
					},
				)}
			>
				<button
					id={`trigger-${id}`}
					className={cx(styles.AccordionItem_trigger, {
						[styles.AccordionItem_trigger___isOpen]: isOpen,
					})}
					onClick={() => setHeight(!isOpen ? 'auto' : 0)}
					onFocus={() => setIsFocus(true)}
					onBlur={() => setIsFocus(false)}
					aria-expanded={isOpen}
					aria-controls={`panel-${id}`}
				>
					<span className={styles.AccordionItem_icon} />
					<span className={styles.AccordionItem_title}>{title}</span>
				</button>
			</h3>
			<AnimateHeight
				id={`panel-${id}`}
				className={cx(styles.AccordionItem_panel, { [styles.AccordionItem_panel___isOpen]: isOpen })}
				contentClassName={styles.AccordionItem_panel_inner}
				height={height}
				duration={300}
				role="region"
				aria-labelledby={`trigger-${id}`}
			>
				<RichText content={htmlContent} className={styles.AccordionItem_content} />
			</AnimateHeight>
		</>
	);
};
