
import React, { useContext } from 'react';
import { get } from 'env-var';
import { DictionaryFactory } from 'domain/content/dictionary/dictionaryFactory';
import { DictionaryInterface } from 'domain/content/dictionary/dictionaryInterface';

const DEFAULT_LOCALE: string = get('DEFAULT_LOCALE').default('en-US').required().asString();

const DictionaryContext = React.createContext<Partial<DictionaryInterface>>(DictionaryFactory([], DEFAULT_LOCALE));

export interface DictionaryContextProviderProps {
	children?: React.ReactNode;
	dictionary: Content.DictionaryItem[];
	culture: string;
}
export const DictionaryContextProvider: React.FC<DictionaryContextProviderProps> = ({
	dictionary,
	culture,
	children,
}) => {
	const dictionaryRepository = DictionaryFactory(dictionary, culture);
	return <DictionaryContext.Provider value={dictionaryRepository}>{children}</DictionaryContext.Provider>;
};

export function useDictionaryContext(): Partial<DictionaryInterface> {
	return useContext(DictionaryContext);
}
