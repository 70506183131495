import cx from 'classnames';
import styles from './SocialLinks.module.scss';
import { ReactComponent as IconFacebook } from 'ui/icons/icon_facebook.svg';
import { ReactComponent as IconX } from 'ui/icons/icon_x.svg';
import { ReactComponent as IconYouTube } from 'ui/icons/icon_youtube.svg';
import { ReactComponent as IconInstagram } from 'ui/icons/icon_instagram.svg';
import { ReactComponent as IconLinkedIn } from 'ui/icons/icon_linkedin.svg';
import { Icon, Link } from 'ui/components';

export interface SocialLinksProps {
	className?: string;
	items?: Models.SocialLink[];
	theme: UI.GeneralColorTheme;
}

export const SocialLinks: React.FC<SocialLinksProps> = ({ className, items, theme = 'dark' }) => {
	if (!items) {
		return null;
	}

	const renderIcon = (item: Models.SocialLink) => {
		switch (item.type) {
			case 'facebook':
				return <IconFacebook className={`icon-${item.type}`} aria-hidden="true" />;
			case 'x':
				return <IconX className={`icon-${item.type}`} aria-hidden="true" />;
			case 'youtube':
				return <IconYouTube className={`icon-${item.type}`} aria-hidden="true" />;
			case 'instagram':
				return <IconInstagram className={`icon-${item.type}`} aria-hidden="true" />;
			case 'linkedin':
				return <IconLinkedIn className={`icon-${item.type}`} aria-hidden="true" />;
		}
	};

	return (
		<ul className={cx(styles.SocialLinks, styles[`SocialLinks___theme-${theme}`], className)}>
			{items.map((item, index) => (
				<li key={index} className={styles.SocialLinks_item}>
					<Link className={styles.SocialLinks_link} aria-label={item.title} url={item.url} {...item}>
						<Icon className={styles.SocialLinks_icon} title={item.title} size={'xl'}>
							{renderIcon(item)}
						</Icon>
					</Link>
				</li>
			))}
		</ul>
	);
};
