import cx from 'classnames';
import styles from './ErrorCard.module.scss';
import { Heading, Link, LinkButton, GridCell } from 'ui/components';

export interface ErrorCardProps {
	className?: string;
	heading?: string;
	kicker?: string;
	text?: string;
	link?: {
		name: string;
		target?: string;
		url: string;
	};
	settings: unknown;
}

// eslint-disable-next-line no-warning-comments
// TODO:  OBS! No Feature exists for this component
export const ErrorCard: React.FC<ErrorCardProps> = ({ className, heading, kicker, text, link, settings }) => {
	const { displayLinkAsButton, desktopWidth, mobileWidth } = settings?.['properties'] ?? {};
	const contentInner = (
		<div className={styles.ErrorCard_textbox}>
			<div className={styles.ErrorCard_kicker}>{kicker}</div>
			<div className={styles.ErrorCard_heading}>
				<Heading headingLevel="h1">{heading}</Heading>
			</div>
			<div className={styles.ErrorCard_text} dangerouslySetInnerHTML={{ __html: text }}></div>
			{displayLinkAsButton && link && (
				<LinkButton url={link?.url} {...link} className={styles.ErrorCard_button} style="primary">
					{link.name}
				</LinkButton>
			)}
		</div>
	);

	let content;
	if (link && !displayLinkAsButton) {
		content = (
			<Link
				className={cx(styles.ErrorCard, styles.ErrorCard___link, className)}
				url={link.url}
				target={link.target}
			>
				{contentInner}
			</Link>
		);
	} else {
		content = <div className={cx(styles.ErrorCard, className)}>{contentInner}</div>;
	}
	return (
		<GridCell desktopWidth={desktopWidth} mobileWidth={mobileWidth}>
			{content}
		</GridCell>
	);
};
