import classNames from 'classnames';
import { Linkbox } from 'ui/components';
import styles from './DocumentList.module.scss';
import { ReactComponent as DownloadLogo } from 'ui/icons/mst_download.svg';

export interface DocumentProps {
	extension?: string;
	name: string;
	url: string;
}
export interface DocumentListProps {
	documents?: DocumentProps[];
	header?: string;
}

export const DocumentList: React.FC<DocumentListProps> = ({ header, documents = [] }) => {
	const links = documents.map((doc) => {
		const fileName = doc.extension ? `${doc.name}.${doc.extension}` : doc.name;

		return {
			text: fileName,
			name: fileName,
			url: doc.url,
			target: '_blank',
			extension: doc.extension,
		} as UI.LinkSimple;
	});

	return (
		<Linkbox
			className={classNames(styles.DocumentList_Linkbox)}
			backgroundColor={'var(--color-light--3)'}
			heading={header}
			linkboxItems={links}
			linkIcon={DownloadLogo}
		/>
	);
};
