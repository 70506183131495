import { axiosInstance as axios } from 'helpers/axios/axiosInstance';
import { CONTENT_API_URL } from './_constants';
import logger from 'helpers/logger';
import { validateHost } from 'helpers/requestHost/requestHost';
import { minimalError } from 'helpers/error/minimalError';

export interface BaseRequestInterface {
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	url?: string;
	accessToken?: string;
}
export interface RequestInterface extends BaseRequestInterface {
	query?: string;
	method: 'POST';
	redirectAble: boolean;
}

const buildHeaders = ({ domain, preview, previewData }): { [key: string]: string } => {
	const headers = {};
	headers['Content-Type'] = 'application/json';
	headers['Hostname'] = domain;

	if (preview && typeof previewData === 'object') {
		headers['Authorization'] = previewData?.data?.auth;
	}
	return headers;
};

export const queryCmsContent = <TResponse = unknown>({
	host,
	preview,
	previewData,
	method,
	query,
	url,
	redirectAble,
}: RequestInterface): Promise<TResponse> => {
	if (!CONTENT_API_URL) {
		logger.warn('Internal Server Error \nContent api url is not defined');
	}

	if (!validateHost(Array.isArray(host) ? host[0] : host)) {
		logger.warn(
			`_api.queryCmsContent() exited before calling CMS. \n Host not found. \n url: ${url}. \n host: ${host}. query: ${query}`,
		);
	}

	const domain = host;
	const headers = buildHeaders({ domain, preview, previewData });
	if (redirectAble) {
		headers['redirectable'] = 'yes';
	}
	const contentUrl = encodeURI(`${CONTENT_API_URL}${url ? url : ''}${preview ? '?preview=1' : ''}`);

	return axios({
		url: contentUrl,
		method: method,
		maxRedirects: 0,
		headers,
		data: {
			query,
		},
	})
		.then((res) => {
			const { data } = res.data;
			const result = data ?? res.data;

			if (typeof result !== 'object') return result;

			result.preview = preview ? true : false;
			result.previewData = previewData ? previewData : null;

			return result;
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			const statusCode = Number(error?.response?.status);

			if (statusCode === 301 || statusCode === 302 || statusCode === 307 || statusCode === 308) {
				const statusName = statusCode
					? statusCode === 301
						? 'Moved Permanently'
						: statusCode === 302
						? 'Found'
						: statusCode === 307
						? 'Temporary Redirect'
						: statusCode === 308
						? 'Permanent Redirect'
						: ''
					: 'statusCode not definded';
				logger.debug(
					`[${statusCode}] _api.queryCmsContent() - Redirection: ${statusName} \n Location: ${error?.response?.headers?.location}\n domain: ${domain}`,
				);
				throw error;
			}

			if (statusCode === 404) {
				logger.debug(
					`[${statusCode}] _api.queryCmsContent() \nPage not found \n ${message}. \n contentUrl: ${contentUrl}. \n query: ${query}. \n domain: ${domain}`,
				);
				throw error;
			}

			if (statusCode === 401 || statusCode === 403) {
				logger.debug(`[${statusCode}] _api.queryCmsContent()`, error?.response?.data?.data);
				return error?.response?.data?.data;
			}
			logger.error(
				`[${statusCode}] _api.queryCmsContent() - Undetected API error \n ${message}. \n contentUrl: ${contentUrl}. \n domain: ${domain}. \n query: ${query}`,
				query,
				headers,
				minimalError(error),
			);
			throw error;
		});
};
