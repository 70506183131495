import cx from 'classnames';
import React from 'react';
import { Container, FooterCard, Grid, GridCell, LinkList, Logo, LogoProps, RichText, SocialLinks } from 'ui/components';
import styles from './Footer.module.scss';

export interface FooterProps {
	className?: string;
	logo?: LogoProps;
	cards?: Models.FooterCard[];
	partnerSites?: Models.FooterCard;
	languageSelector?: {
		languageSelector: string;
		heading: string;
		links: UI.LinkListLink[];
	};
	social?: {
		heading: string;
		links: Models.SocialLink[];
	};
	links?: UI.LinkBaseProps[];
	theme: UI.GeneralColorTheme;
}

const Footer: React.FC<FooterProps> = ({
	className,
	logo,
	cards,
	languageSelector,
	partnerSites,
	social,
	links,
	theme = 'dark',
}) => {
	return (
		<footer className={cx(styles.Footer, styles[`Footer___theme-${theme}`], className)}>
			<Container width="std">
				<Grid className={cx(styles.Footer_grid)} wrap={true} container={true}>
					<GridCell
						className={cx(styles.Footer_logoContainer)}
						mobileWidth="100"
						tabletWidth="30"
						desktopWidth="30"
					>
						<Logo className={cx(styles.Footer_logo)} size="sm" {...logo} />
					</GridCell>
					<GridCell mobileWidth="100" tabletWidth="70" desktopWidth="70">
						<Grid className={cx(styles.Footer_cards)} wrap={true} container={true}>
							{cards?.map((card, index) => {
								return (
									<GridCell key={index} mobileWidth="100" tabletWidth="50" desktopWidth="33">
										<FooterCard heading={card.heading} theme={theme}>
											{card.links && <LinkList links={card.links} />}
											{card.text && <RichText content={card.text} />}
										</FooterCard>
									</GridCell>
								);
							})}
							<GridCell mobileWidth="100" tabletWidth="50" desktopWidth="33">
								{languageSelector?.links?.length > 0 && (
									<FooterCard heading={languageSelector?.heading} theme={theme}>
										<LinkList links={languageSelector?.links} />
									</FooterCard>
								)}
							</GridCell>
							<GridCell mobileWidth="100" tabletWidth="50" desktopWidth="33">
								<FooterCard
									className={cx(styles.Footer_linklist)}
									heading={partnerSites?.heading}
									theme={theme}
								>
									<LinkList textDecoration links={partnerSites?.links} />
								</FooterCard>
							</GridCell>
							<GridCell mobileWidth="100" tabletWidth="50" desktopWidth="33">
								<FooterCard heading={social?.heading} theme={theme}>
									<SocialLinks className={styles.Footer_social} items={social?.links} theme={theme} />
								</FooterCard>
							</GridCell>
						</Grid>
					</GridCell>
				</Grid>
				{links && (
					<div className={styles.Footer_bottomNav}>
						<LinkList
							className={styles.Footer_bottomNav_links}
							links={links}
							layout={'row'}
							size={'sm'}
							textDecoration
						/>
					</div>
				)}
			</Container>
		</footer>
	);
};

export { Footer };
