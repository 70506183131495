import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';
import cx from 'classnames';
import styles from './Slider.module.scss';
import { SliderButton, SliderButtonProps } from './SliderButton';
import { Heading, Spinner } from 'ui/components';
import { ReactComponent as SliderArrow } from 'ui/icons/icon_slider-arrow.svg';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export interface SliderOptions {
	slideArrowNext?: string;
	slideArrowPrevious?: string;
	breakpoints: SwiperOptions['breakpoints'];
}

export interface SliderProps {
	className?: string;
	heading?: string;
	buttons?: SliderButtonProps[];
	slides: React.ReactElement[];
	sliderOptions?: SliderOptions;
	isLoading?: boolean;
	fallbackText?: string;
}

export const Slider: React.FC<SliderProps> = ({
	className,
	heading,
	buttons,
	slides,
	sliderOptions = { slideArrowNext: 'Next slide', slideArrowPrevious: 'Previous slide', breakpoints: {} },
	isLoading = false,
	fallbackText,
}) => {
	const dictionary = useDictionaryContext();
	const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
	const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
	const theSwiper = useRef<SwiperRef>(null);
	const sliderOptionsDefault: SwiperOptions['breakpoints'] = {
		320: {
			slidesPerView: 1,
			slidesPerGroup: 1,
			spaceBetween: 20,
			pagination: false,
		},
		768: {
			slidesPerView: 2,
			slidesPerGroup: 2,
			spaceBetween: 40,
		},
		1024: {
			slidesPerView: 3,
			slidesPerGroup: 3,
			spaceBetween: 40,
		},
		1248: {
			slidesPerView: 4,
			slidesPerGroup: 4,
			spaceBetween: 60,
		},
	};
	const hasSlides = slides?.length > 0;

	useEffect(() => {
		theSwiper?.current?.swiper?.slideTo(0);
	}, [slides]);

	return (
		<div className={cx(styles.Slider, className)}>
			{(heading || buttons?.length) && (
				<div className={styles.Slider_header}>
					<Heading className={styles.Slider_heading} headingLevel="h2" style="md">
						{heading}
					</Heading>
					{buttons?.length > 0 && (
						<div className={cx(styles.Slider_buttons, styles.Slider_buttons___desktop)}>
							{buttons.map((button: SliderButtonProps, index: number) => (
								<SliderButton key={index} {...button} />
							))}
						</div>
					)}
				</div>
			)}
			<noscript>
				<p>
					<em>
						{dictionary.getValue(
							'Accessibility.NoJavaScript.Component',
							null,
							'Din browser understøtter ikke JavaScript hvilket kræves for dette komponent.',
						)}
					</em>
				</p>
			</noscript>
			{isLoading && <Spinner size="large" />}
			<div className={styles.Slider_slides}>
				{!isLoading && (
					<>
						<button
							className={cx(styles.Slider_arrow, styles.Slider_arrow___prev, 'hideIfNoScript')}
							ref={(node) => setPrevEl(node)}
							aria-label={sliderOptions?.slideArrowPrevious}
						>
							<SliderArrow />
						</button>
						<button
							className={cx(styles.Slider_arrow, styles.Slider_arrow___next, 'hideIfNoScript')}
							ref={(node) => setNextEl(node)}
							aria-label={sliderOptions?.slideArrowNext}
						>
							<SliderArrow />
						</button>
						{!hasSlides && fallbackText && <p>{fallbackText}</p>}
						{hasSlides && (
							<Swiper
								ref={theSwiper}
								className={styles.Slider_swiper}
								modules={[Navigation, Pagination, Scrollbar, A11y]}
								navigation={{ prevEl, nextEl }}
								pagination={{
									clickable: true,
									type: 'bullets',
								}}
								breakpoints={sliderOptions?.breakpoints || sliderOptionsDefault}
								keyboard
							>
								{slides?.map((slide, index) => (
									<SwiperSlide key={index} className={styles.Slider_slide}>
										{slide}
									</SwiperSlide>
								))}
							</Swiper>
						)}
					</>
				)}
			</div>
			{buttons?.length > 0 && (
				<div className={cx(styles.Slider_buttons, styles.Slider_buttons___mobile)}>
					{buttons.map((button: SliderButtonProps, index: number) => (
						<SliderButton key={index} {...button} />
					))}
				</div>
			)}
		</div>
	);
};
