import cx from 'classnames';
import FocusTrap from 'focus-trap-react';
import { functionNotNull } from 'helpers/functionNotNull/functionNotNull';
import { generateId } from 'helpers/id';
import { useEffect, useRef, useState } from 'react';
import {
	ErrorMessage,
	FormfieldCheckbox,
	FormfieldCheckboxCallbackProps,
	FormfieldCheckboxProps,
	FormfieldGroup,
	Label,
} from 'ui/components';
import { useEscapeKey } from 'ui/hooks/useEscapeKey';
import { useOutsideClick } from 'ui/hooks/useOutsideClick';
import styles from './FormfieldCombobox.module.scss';

export interface FormfieldComboboxBaseProps {
	className?: string;
	id: string;
	name: string;
	style?: UI.FormfieldStyle;
	checkBoxes: FormfieldCheckboxProps[];
	checkBoxesAreLoading?: boolean;
	toggleButtonText: string;
	onSelectedChanged?: (id: string, boxes: FormfieldCheckboxCallbackProps[]) => void;
	multiselect?: boolean;
	firstOptionSelectsAll?: boolean;
	state?: UI.FormfieldState;
	rules?: Record<string, unknown>;
	errorMessage?: string;
	children?: React.ReactNode;
}

export interface FormfieldComboboxProps extends FormfieldComboboxBaseProps {
	label: UI.Label;
}

export const FormfieldCombobox: React.FC<FormfieldComboboxProps> = ({
	className,
	id,
	label,
	toggleButtonText,
	name,
	style = 'bordered',
	checkBoxes,
	onSelectedChanged,
	multiselect = true,
	firstOptionSelectsAll,
	state,
	errorMessage,
	children,
}) => {
	const [formgroupId] = useState(`formgroup-${id}-${generateId()}`);
	const [toggleId] = useState(`toggle-${id}-${generateId()}`);
	const [open, setOpen] = useState(false);
	const [checkboxStates, setCheckboxStates] = useState<FormfieldCheckboxProps[]>([]);
	const [toggleButtonTextState, setToggleButtonTextState] = useState(toggleButtonText ?? '');
	const ref = useRef<HTMLInputElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const fieldRef = useRef<HTMLDivElement>(null);
	const { hasError } = state ?? {};

	const toggleFormfieldGroup = (e?: React.MouseEvent) => {
		e?.preventDefault();
		setOpen(!open);
	};

	const closeEscape = (): void => {
		if (open) toggleFormfieldGroup();
	};

	const selectedCheckboxes = (boxes: FormfieldCheckboxProps[]) => boxes.filter((box) => box.checked);

	const checkboxChanged = (checkbox: FormfieldCheckboxCallbackProps): void => {
		const copiedBoxes = [...checkboxStates];
		const checkedIndex = copiedBoxes.findIndex((box) => box.id === checkbox.id);

		if (checkedIndex >= 0) {
			copiedBoxes[checkedIndex].checked = checkbox.checked;
		}

		if (multiselect) {
			if (firstOptionSelectsAll && checkedIndex === 0) {
				const allChecked = copiedBoxes[0].checked;
				const updatedBoxes = copiedBoxes.map((item) => {
					return { ...item, checked: allChecked };
				});
				setCheckboxStates(updatedBoxes);
				functionNotNull(onSelectedChanged) && onSelectedChanged(id, selectedCheckboxes(updatedBoxes));
			} else {
				setCheckboxStates(copiedBoxes);
				functionNotNull(onSelectedChanged) && onSelectedChanged(id, selectedCheckboxes(copiedBoxes));
			}
		} else {
			const updatedBoxes = copiedBoxes.map((item) => {
				const toReturn = { ...item };
				toReturn.checked = toReturn.id === checkbox.id && checkbox.checked;
				return toReturn;
			});
			setCheckboxStates(updatedBoxes);
			functionNotNull(onSelectedChanged) && onSelectedChanged(id, selectedCheckboxes(updatedBoxes));
		}
	};

	useEffect(() => {
		setCheckboxStates(checkBoxes);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkBoxes]);

	useEffect(() => {
		const selectedValues = selectedCheckboxes(checkboxStates)?.map((checkbox) => checkbox.labelText);
		setToggleButtonTextState(selectedValues?.length ? selectedValues.join('; ') : toggleButtonText ?? '');
	}, [checkboxStates, toggleButtonText]);

	useEscapeKey(closeEscape);
	useOutsideClick(closeEscape, fieldRef);
	return (
		<FocusTrap active={open}>
			<div className={cx(styles.FormfieldCombobox, className)}>
				{label && !label.visuallyHidden && (
					<Label
						asParagraph={true}
						className={styles.FormfieldCombobox_label}
						id={generateId(3)}
						state={{ isHidden: false, ...state }}
					>
						{label.text}
					</Label>
				)}
				<div className={styles.FormfieldCombobox_fieldContainer}>
					<div
						ref={fieldRef}
						className={cx(
							styles.FormfieldCombobox_field,
							{
								[styles.FormfieldCombobox_field___hasSelected]:
									selectedCheckboxes(checkboxStates).length > 0,
							},
							{
								[styles.FormfieldCombobox_field___open]: open,
							},
						)}
					>
						<button
							ref={buttonRef}
							onClick={toggleFormfieldGroup}
							aria-expanded={open}
							className={cx(styles.FormfieldCombobox_toggle, [
								styles[`FormfieldCombobox_toggle___${style}`],
							])}
							aria-controls={formgroupId}
							id={toggleId}
						>
							<span>{toggleButtonTextState}</span>
						</button>
						<FormfieldGroup
							id={formgroupId}
							name={name}
							label={{
								text: label.text,
								visuallyHidden: true,
							}}
							className={cx(styles.FormfieldCombobox_formfieldgroup, {
								[styles.FormfieldCombobox_formfieldgroup___closed]: !open,
							})}
						>
							{checkboxStates?.map((checkbox, index) => (
								<FormfieldCheckbox
									forwardedRef={index === 0 ? ref : null}
									key={index}
									fieldGroup={true}
									multiline={false}
									onCheckedChanged={checkboxChanged}
									{...checkbox}
								/>
							))}
							{children}
						</FormfieldGroup>
					</div>
					{hasError && (
						<ErrorMessage className={styles.FormfieldCombobox_errorMessage} id={`${id}-error`}>
							{errorMessage}
						</ErrorMessage>
					)}
				</div>
			</div>
		</FocusTrap>
	);
};
