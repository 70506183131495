import { ArrowRightLink, Heading, Link, Picture } from 'ui/components';
import styles from './CampaignTeaserItem.module.scss';

export interface CampaignTeaserItemProps {
	heading: string;
	image: UI.Image;
	link: UI.LinkBaseProps;
}

export const CampaignTeaserItem: React.FC<CampaignTeaserItemProps> = ({ heading, image, link }) => {
	const CampaignTeaserItemContainer = link?.url ? Link : 'div';

	return (
		<CampaignTeaserItemContainer className={styles.CampaignTeaserItem} url={link?.url}>
			<div className={styles.CampaignTeaserItem_imageContainer}>
				{image && (
					<Picture
						className={styles.CampaignTeaserItem_image}
						url={image.url}
						altText={image.altText}
						aspectRatio={16 / 10}
						isCover
					/>
				)}
			</div>
			<Heading className={styles.CampaignTeaserItem_heading} headingLevel="h3" style="2xs">
				{heading}
			</Heading>
			{link?.url && <ArrowRightLink className={styles.CampaignTeaserItem_link}>{link.name}</ArrowRightLink>}
		</CampaignTeaserItemContainer>
	);
};
