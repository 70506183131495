import { List, Spinner } from 'ui/components';
import { BlurbCardProps } from 'ui/components/2-molecules';

export interface NewsListProps {
	rootFolderId?: number;
	count?: number;
	offset?: number;
	link?: {
		url: string;
		name?: string;
		target?: string;
	};
	newsFetcher: (
		rootId: number,
		limit: number,
		offset: number,
	) => {
		articles?: BlurbCardProps[];
		isLoading: boolean;
	};
}

export const NewsList: React.FC<NewsListProps> = ({ rootFolderId, count, offset, link, newsFetcher }) => {
	const rootId = rootFolderId ? rootFolderId : 0;
	const limitQuery = count === 0 ? null : count;
	const offsetQuery = offset >= 0 ? offset : null;
	const newsResult = newsFetcher(rootId, limitQuery, offsetQuery);

	if (newsResult.isLoading) return <Spinner />;
	if (!newsResult.articles) return <></>;

	return (
		<List
			items={newsResult.articles.map((item) => ({
				...item,
				settings: { desktopWidth: item.settings?.desktopWidth, mobileWidth: item.settings?.mobileWidth },
			}))}
			desktopWidth="100"
			mobileWidth="100"
			link={link}
		></List>
	);
};
