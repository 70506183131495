import cx from 'classnames';
import { useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Container, DesktopNavigation, Grid, GridCell, MobileNavigation, SearchBox, SkipToMain } from 'ui/components';
import { useBreakpoint } from 'ui/hooks/useBreakpoint';
import styles from './Header.module.scss';
import { nullCheck } from 'helpers/functionNotNull/functionNotNull';

export interface HeaderProps {
	headerColorTheme?: UI.HeaderColorTheme;
	className?: string;
	logoArea?: React.ReactNode;
	navigation: UI.Navigation;
	toggleMenuButtonText: string;
	searchBoxProps?: {
		closeButtonText: string;
		searchButtonText: string;
		searchInputPlaceholder: string;
	};
	skipToContent: {
		title: string;
		description: string;
	};
}

export const Header: React.FC<HeaderProps> = ({
	headerColorTheme = 'dark',
	className,
	logoArea,
	navigation,
	toggleMenuButtonText,
	searchBoxProps,
	skipToContent,
}) => {
	const isDesktop = useBreakpoint('lg', 'min');
	const inputRef = useRef(null);
	const [searchBoxHeight, setSearchBoxHeight] = useState<'auto' | number>(0);
	const searchBoxAnimationDuration = 500;
	const toggleSearchBox = () => setSearchBoxHeight(searchBoxHeight === 0 ? 'auto' : 0);
	const handleClose = () => setSearchBoxHeight(0);
	const isSearchPageIncluded = nullCheck(searchBoxProps);

	return (
		<>
			<header className={cx(styles.Header, styles[`Header___${headerColorTheme}`], className)} role="banner">
				{skipToContent && (
					<SkipToMain link="#main" title={skipToContent.description}>
						{skipToContent.title}
					</SkipToMain>
				)}
				<Container width="2xl" className={styles.Header_inner}>
					<Grid className={styles.Header_grid} nogutter={true} center={false}>
						<GridCell
							className={styles.Header_identity}
							desktopWidth="20"
							tabletWidth="33"
							mobileWidth="50"
						>
							{logoArea}
						</GridCell>
						<GridCell
							className={styles.Header_navigation}
							desktopWidth="80"
							tabletWidth="66"
							mobileWidth="50"
						>
							{navigation && (
								<>
									{isDesktop ? (
										<DesktopNavigation
											{...navigation}
											serviceNavigation={{
												...navigation?.serviceNavigation,
											}}
											toggleSearch={() => toggleSearchBox()}
											colorTheme={headerColorTheme}
											isSearchPageIncluded={isSearchPageIncluded}
										/>
									) : (
										<MobileNavigation
											{...navigation}
											serviceNavigation={navigation?.serviceNavigation}
											toggleMenuButtonText={toggleMenuButtonText}
											colorTheme={headerColorTheme}
											isSearchPageIncluded={isSearchPageIncluded}
										/>
									)}
								</>
							)}
						</GridCell>
					</Grid>
				</Container>
			</header>
			{isSearchPageIncluded && (
				<>
					{isDesktop && (
						<AnimateHeight
							className={styles.SubNavigation}
							height={searchBoxHeight}
							duration={searchBoxAnimationDuration}
							onHeightAnimationEnd={() => {
								if (searchBoxHeight !== 0) inputRef?.current?.focus();
							}}
						>
							<SearchBox
								handleClose={handleClose}
								handleSearch={(searchValue) =>
									navigation?.serviceNavigation?.handleSearch(searchValue) && handleClose()
								}
								isOpen={searchBoxHeight !== 0}
								forwardedRef={inputRef}
								{...searchBoxProps}
							/>
						</AnimateHeight>
					)}
				</>
			)}
		</>
	);
};
