import { Heading, Link, Paragraph, Subheading } from 'ui/components';
import styles from './SliderNewsItem.module.scss';

export type SliderNewsItemProps = UI.PageItem;

export const SliderNewsItem: React.FC<SliderNewsItemProps> = ({ heading, text, dateString, link }) => {
	const SlideContainer = link?.url ? Link : 'div';

	return (
		<SlideContainer className={styles.SliderNewsItem} url={link?.url}>
			{dateString && (
				<Subheading className={styles.SliderNewsItem_date} style="secondary">
					{dateString}
				</Subheading>
			)}
			<Heading className={styles.SliderNewsItem_heading} headingLevel="h3" style="2xs">
				{heading}
			</Heading>
			{text && <Paragraph className={styles.SliderNewsItem_text}>{text}</Paragraph>}
		</SlideContainer>
	);
};
