import cx from 'classnames';
import { Heading, Paragraph } from 'ui/components';
import styles from './NewsSubscriptionReceipt.module.scss';

export interface NewsSubscriptionReceiptProps {
	className?: string;
	recipientEmailLabel: string;
	/** Name is optional in first version */
	recipientNameLabel?: string;
	recipient: {
		email: string;
		/** Name is optional in first version */
		name?: string;
	};
	topicGroups: {
		name: string;
		topics: {
			name: string;
		}[];
	}[];
}

export const NewsSubscriptionReceipt: React.FC<NewsSubscriptionReceiptProps> = ({
	className,
	recipientNameLabel,
	recipientEmailLabel,
	recipient,
	topicGroups,
}) => {
	return (
		<div className={cx(styles.NewsSubscriptionReceipt, className)}>
			<div className={styles.NewsSubscriptionReceipt_recipient}>
				{recipient?.name && (
					<Paragraph className={styles.NewsSubscriptionReceipt_recipientInfo}>
						<strong>{recipientNameLabel}:</strong> {recipient.name}
					</Paragraph>
				)}
				{recipient?.email && (
					<Paragraph className={styles.NewsSubscriptionReceipt_recipientInfo}>
						<strong>{recipientEmailLabel}:</strong> {recipient.email}
					</Paragraph>
				)}
			</div>
			{topicGroups?.length > 0 && (
				<div className={styles.NewsSubscriptionReceipt_topics}>
					{topicGroups.map((topicGroup, index) => (
						<div className={styles.NewsSubscriptionReceipt_topicGroup} key={index}>
							<Heading
								className={styles.NewsSubscriptionReceipt_topicGroup_heading}
								headingLevel="h3"
								style="2xs"
							>
								{topicGroup.name}
							</Heading>
							{topicGroup.topics?.length > 0 && (
								<ul>
									{topicGroup.topics.map((topic, index) => (
										<li key={index}>{topic.name}</li>
									))}
								</ul>
							)}
						</div>
					))}
				</div>
			)}
		</div>
	);
};
