import React from 'react';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

// Next.js sends back empty html from the server
// SSR should not depend on Redux Persist - if it does nothing will be prerendered
// https://github.com/vercel/next.js/discussions/16389#discussioncomment-469660

type Props = {
	loading?: boolean;
	persistor: Persistor;
	children?: React.ReactNode;
};

/**
 * SSR aware persist gate
 * @param props Same as the original PersistGate
 * @returns persisted or un-persisted component
 */

export const PersistGateSSR: React.FC<Props> = (props) => {
	if (typeof window === 'undefined') {
		// Not on browser, nothing to persist here
		return <>{props.children}</>;
	} else {
		return (
			// suppressHydrationWarning is a quick fix and should probably be revisited
			// https://blog.hao.dev/render-client-side-only-component-in-next-js
			// https://reactjs.org/docs/react-dom.html#hydrate
			<div suppressHydrationWarning={true}>
				<PersistGate loading={props.loading} persistor={props.persistor}>
					{props.children}
				</PersistGate>
			</div>
		);
	}
};
