import cx from 'classnames';
import { Container, Link } from 'ui/components';
import styles from './Breadcrumb.module.scss';

export interface BreadcrumbProps {
	breadcrumbItems: Array<BreadcrumbItemProps>;
	breadCrumbLabel?: string;
	theme: UI.GeneralColorTheme;
}

interface BreadcrumbItemProps {
	url?: string;
	title: string;
}

export const Breadcrumb: React.VFC<BreadcrumbProps> = ({
	breadcrumbItems,
	breadCrumbLabel = 'Breadcrumb',
	theme = 'dark',
}) => (
	<nav aria-label={breadCrumbLabel} className={cx(styles.Breadcrumb, styles[`Breadcrumb___theme-${theme}`])}>
		<Container width="2xl" className={styles.Breadcrumb_outer} noPadding>
			<Container width="std" className={styles.Breadcrumb_inner}>
				<ol className={styles.Breadcrumb_items}>
					{breadcrumbItems.map((breadcrumbItem: BreadcrumbItemProps, index) => (
						<li key={index} className={styles.Breadcrumb_item}>
							<Link
								className={cx(styles.Breadcrumb_url)}
								{...breadcrumbItem}
								aria-current={index === breadcrumbItems.length - 1 ? 'page' : null}
							>
								{breadcrumbItem.title}
							</Link>
						</li>
					))}
				</ol>
			</Container>
		</Container>
	</nav>
);
