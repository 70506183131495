import cx from 'classnames';
import styles from './ArticlePicture.module.scss';
import { Caption, Picture } from 'ui/components';

export interface ArticlePictureProps {
	picture: UI.ArticlePicture;
	className?: string;
	pictureClassName?: string;
}

export const ArticlePicture: React.FC<ArticlePictureProps> = ({ className, picture, pictureClassName }) => {
	const { image, caption } = picture ?? {};

	return (
		<figure className={cx(styles.ArticlePicture, className)}>
			{image && (
				<Picture
					aspectRatio={0.5625}
					url={image.url}
					altText={image.altText}
					className={cx(pictureClassName)}
				/>
			)}
			{caption && <Caption className={styles.ArticlePicture_caption}>{caption}</Caption>}
		</figure>
	);
};
