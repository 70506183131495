import cx from 'classnames';
import styles from './NavigationCards.module.scss';
import { Container, Heading, NavigationCard, NavigationCardsLinkType, NavigationCardProps } from 'ui/components';

export interface NavigationCardsProps {
	className?: string;
	heading?: string;
	cards: NavigationCardProps[];
	layout: '3' | '4' | '2-3';
	backgroundColor: 'white' | 'light' | 'dark';
	linkType?: NavigationCardsLinkType;
}

export const NavigationCards: React.FC<NavigationCardsProps> = ({
	className,
	heading,
	cards,
	layout = '3',
	backgroundColor = 'white',
	linkType,
}) => {
	return (
		<div
			className={cx(
				styles.NavigationCards,
				className,
				{ [styles.NavigationCards___bg]: backgroundColor && backgroundColor !== 'white' },
				styles[`NavigationCards___bg-${backgroundColor}`],
			)}
		>
			<Container width="std">
				{heading && (
					<Heading className={styles.NavigationCards_heading} headingLevel="h2" style={'md'}>
						{heading}
					</Heading>
				)}
				{cards && (
					<ul
						className={cx(styles.NavigationCards_cards, styles[`NavigationCards_cards___layout-${layout}`])}
					>
						{cards.map((card, index) => (
							<li key={index} className={cx(styles.NavigationCards_item)}>
								<NavigationCard
									className={cx(styles.NavigationCards_card)}
									backgroundColor={backgroundColor !== 'white' ? 'white' : 'light'}
									centered={card.image?.url || card.icon?.url ? true : card.centered}
									linkType={linkType}
									{...card}
								/>
							</li>
						))}
					</ul>
				)}
			</Container>
		</div>
	);
};
