import cx from 'classnames';
import React from 'react';
import { Heading, LinkButton, Picture, RichText } from 'ui/components';
import styles from './Banner.module.scss';

export type BannerColorTheme = 'light' | 'dark';

export interface BannerProps {
	className?: string;
	heading: string;
	text?: string;
	link?: UI.LinkBaseProps;
	image: UI.Image;
	imagePosition: 'left' | 'right';
	colorTheme: BannerColorTheme;
}

export const Banner: React.FC<BannerProps> = ({
	className,
	heading,
	text,
	link,
	image,
	imagePosition = 'left',
	colorTheme = 'dark',
}) => {
	return (
		<div
			className={cx(
				styles.Banner,
				styles[`Banner___image-${imagePosition}`],
				styles[`Banner___${colorTheme}`],
				className,
			)}
		>
			<div className={styles.Banner_inner}>
				<div className={styles.Banner_imageContainer}>
					{image?.url && (
						<Picture className={styles.Banner_image} url={image.url} altText={image.altText} isCover />
					)}
				</div>
				<div className={styles.Banner_content}>
					<Heading className={styles.Banner_heading} headingLevel="h2" style="lg">
						{heading}
					</Heading>
					{text && <RichText className={styles.Banner_text} content={text} />}

					{link && (
						<LinkButton
							className={styles.Banner_button}
							{...link}
							style={colorTheme === 'dark' ? 'primary-negative' : 'primary'}
						>
							{link.name}
						</LinkButton>
					)}
				</div>
			</div>
		</div>
	);
};
